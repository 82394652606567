@import "@/styles/var.module.scss";

@mixin h1-text {
  font-family: IBM_Plex_Sans_Bold;
  font-size: 46px;
  font-weight: 52px;
  color: #E7E9EC;
}

@mixin h2-text {
  font-family: IBM_Plex_Sans_Normal;
  color: #7C7E87;
  font-size: 18px;
  font-weight: normal;
}

@mixin disable-img-highlight {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}



.downloadInfo {
  position: relative;
  .h1 {
    display: block; font-size: 2em; margin-top: 0.67em; margin-bottom: 0.67em;
    @include h1-text;
    white-space: nowrap;
  }
  .downloadInfoContent {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    .downloadInfoLeft, .downloadInfoImage {
      width: 50%;
    }
    .downloadInfotitle {
      margin-bottom: 65px;
      h2 {
        @include h2-text;
      }
    }
    .downloadInfoData {
      .qrBorder {
        @include disable-img-highlight;
        background-image: linear-gradient(to bottom right, #00A5FF,  #00F2ED);
        background-size: cover;
        padding: 4px;
        box-shadow: 0 0 30px 5px rgba(0,203,253, 0.5);
        border-radius: 20px;
        width: 175px;
        min-width: 175px;
        margin-right: 40px;
        .image {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          background: black;
          border-radius: 15px;
        }
      }
      align-items: center;
      text-align: center;
      display: flex;
      position: relative;
      .downloadInfoQrcodeText {
        position: relative;
        width: 300px;
        .downloadInfolink {
          display: flex;
          gap: 40px;
          margin-bottom: 30px;
          .androidCon, .iOSCon {
            text-align: left;
            width: 130px;
            .desc {
              margin-bottom: 9px;
              font-size: 14px;
              color: #717278;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0.7px;
            }
            svg {
              height: 41px;
              margin-bottom: 10px;
            }
            .svgWrapper {
              position: relative;;
              width: fit-content;
              height: 41px;
              .svgWords {
                position: absolute;
                bottom: -13px;
                width: 100%;
                background-color: #00F1EE;
                border-radius: 0px 0px 10px 10px;
                color: #0A0C19;
                text-align: center;
                font-size: 9px;
                font-weight: 500;
              }
            }
            .appLink {
              position: relative;
              display: block;
              width: fit-content;
              height: fit-content;
              .canvasWrapper {
                position: absolute;
                z-index: 100;
                top: -50%;
                left: 100%;
                margin-left: 20px;
                visibility: hidden;
                canvas {
                  background-color: white;
                  padding: 5px;
                  border-radius: 10px;
                }
                &::before{
                  content: '';
                  position: absolute;
                  top: 50%;
                  right: 100%;
                  margin-top: -10px;
                  border-width: 10px;
                  border-style: solid;
                  border-color: transparent white transparent transparent;
                
                }
              }
              &:hover {
                .canvasWrapper {
                  visibility: visible;
                }
              }
            }
          }
        }
        .infoDesc {
          display: flex;
          gap: 5px;
          color: #717278;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px; /* 140% */
          letter-spacing: 0.05px;
          .text {
            text-align: left;
          }
        }
      }
    }
  }
  .downloadInfoImage {
    @include disable-img-highlight;
    min-width: 50%;
    span {
      height: 100% !important;
    }
  }
}


@media screen and (max-width: 768px) {
  .downloadInfo {
    text-align: center;
    margin-bottom: 60px;
    .downloadInfoContent {
      flex-direction: column-reverse;
      .h1 {
        white-space: normal;
        font-size: 27px;
      }      
      .downloadInfoLeft, .downloadInfoImage {
        width: 100%;
      }
      .downloadInfotitle {
        margin-bottom: 20px;
        margin: auto;
        width: 292px;
        h2 {
          font-size: 15px;
          margin-top: 30px;
        }
      }
      .downloadInfoData {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .qrBorder {
          width: 145px;
          min-width: 145px;
          margin-right: 30px;
          margin-bottom: 20px;
          .image {
            padding: 16px;
          }
        }
        .downloadInfoQrcodeText {
          width: 292px;
          .downloadInfolink {
            .androidCon, .iOSCon {
              .desc {
                margin-left: 8px;
                font-size: 10px;
                letter-spacing: 0.05px;
              }
              .appLink {
                .canvasWrapper {
                  position: absolute;
                  z-index: 100;
                  top: -235%;
                  left: 0%;
                  margin-left: 10px;
                  &::before{
                    content: '';
                    position: absolute;
                    top: 95%;
                    left: 50%;
                    margin-top: 0;
                    transform: translateX(-50%);
                    border-color: white transparent transparent transparent; /* Match the background color */
                  
                  }
                }
                &:hover {
                  .canvasWrapper {
                    visibility: visible;
                  }
                }
              }
            }
          }
          .infoDesc {
            font-size: 9px;
            font-style: normal;
            line-height: 12px; /* 133.333% */
            letter-spacing: 0.045px;
            
          }
        }
      }
    }
    .downloadInfoImage {
      max-width: 100%;
    }
  }
}